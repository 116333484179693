import { DirectTermPlanCodesSchema, LoyaltyTravelAccidentPlanCodesSchema } from "domain/planCodes";
import {
    ApplicantTypeEnum,
    ApplicationModeEnumSchema,
    BenefitOptionSchema,
    DirectTermOfferSchema,
    LoyaltyTierSchema,
    PhoneTypesEnum,
    PolicyStatusSchema,
    QuoteOptionSchema,
    StrictSecondaryAddresseeSchema,
} from "state/ApplicationStateSchema";
import { GendersSchema } from "types/common/Genders";
import { z } from "zod";

export const StatesEnumSchema = z.enum([
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
]);
export type UsStates = z.infer<typeof StatesEnumSchema>;

// NOTE: Temporarily extend StrictSecondaryAddresseeSchema by overriding the state property to be more strict. Eventually, this
// change can be pushed into the rest of the application for better type safety.
const FinalSecondaryAddresseeSchema = z.object({
    ...StrictSecondaryAddresseeSchema.shape,
    state: StatesEnumSchema,
});

const BeneficiaryRelationshipsEnumSchema = z.enum([
    "Spouse",
    "FormerSpouse",
    "Child",
    "Sibling",
    "Father",
    "Mother",
    "Grandchild",
    "Fiancee",
    "Nephew",
    "Niece",
    "DomesticPartner",
    "Aunt",
    "Uncle",
    "Cousin",
    "BusinessAssociate",
]);

export const CoverageTypesSchema = z.enum(["Individual", "Family"]);

export const OfferStateSchema = z.object({
    invitationCode: z.string(),
    planCode: z.string(),
    firstName: z.string().optional(),
    middleInitial: z.string().optional(),
    lastName: z.string().optional(),
    keyCode: z.string(),
    aaaMemberNumber: z.string().optional(),
    memberOfferAvailable: z.boolean(),
    spouseOfferAvailable: z.boolean(),
    membershipLength: z.number().optional(),
    memberSince: z.string().optional(),
    gender: GendersSchema.optional(),

    // FUTURE: Include this via OFFER_FOUND action later
    // formNumber: z.string(),
});

const PolicyToReplaceSchema = z.object({
    company: z.string(),
    policyNumber: z.string().optional(),
});

export const HeightSchema = z.object({
    feet: z.number().int(),
    inches: z.number().int(),
});

export const BeneficiarySchema = z.object({
    firstName: z.string(),
    middleInitial: z.string().optional(),
    lastName: z.string(),
    percentage: z.number().int(),
    relationship: BeneficiaryRelationshipsEnumSchema,
});

const QuotePropertiesSchema = z.object({
    applicantType: ApplicantTypeEnum,
    dateOfBirth: z.string(),
    zipCode: z.string(),
    email: z.string(),
});

export const SelectedCoverageSchema = z.object({
    selectedCoveragePremium: z.number(),
});

export const PersonalInfoSchema = z.object({
    policyNumber: z.string(),
    addressLine1: z.string(),
    addressLine2: z.string().optional(),
    city: z.string(),
    state: StatesEnumSchema,
    phone: z.string(),
    phoneType: PhoneTypesEnum,
    email: z.string(),
});

export const HealthQuestionsSchema = z.object({
    hasSevereMedicalConditions: z.boolean(),
    hadDiagnosticTesting: z.boolean(),
});

const WithSecondaryAddresseeSchema = z.object({
    designatedSecondaryAddressee: z.literal(true),
    secondaryAddressee: FinalSecondaryAddresseeSchema,
});

const WithoutSecondaryAddresseeSchema = z.object({
    designatedSecondaryAddressee: z.literal(false),
});

const OptionalSecondaryAddresseeSchema = z.discriminatedUnion("designatedSecondaryAddressee", [
    WithSecondaryAddresseeSchema,
    WithoutSecondaryAddresseeSchema,
]);

export const StrictApplicationSchema = z
    .object({
        applicationID: z.string(),
        firstName: z.string(),
        middleInitial: z.string().optional(),
        lastName: z.string(),

        selectedCoverageAmount: z.number(),

        beneficiary: BeneficiarySchema,

        paymentFrequency: z.enum(["Monthly"]),

        paymentToken: z.string(),
        agreeToConsent: z.boolean(),
        keyCode: z.string(),
        aaaMemberNumber: z.string(),
    })
    .merge(QuotePropertiesSchema)
    .merge(SelectedCoverageSchema)
    .merge(PersonalInfoSchema)
    .and(OptionalSecondaryAddresseeSchema);

export const CampaignSchema = z.object({
    clubCode: z.string().optional(),
    leadSource: z.string().optional(),
    campaignCode: z.string().optional(),
    offerPurl: z.string().optional(),
    campaignUrl: z.string(),
});

export const BaseSchema = z
    .object({
        applicationMode: ApplicationModeEnumSchema,

        campaign: CampaignSchema,

        /** The state inferred via ZIP code */
        state: z.string().optional(),

        session: z.object({
            sessionID: z.string(),
            timeout: z.number().optional(),
            promptBeforeIdle: z.number().optional(),
        }),

        clubSpecificData: z.object({
            /** The club code determined via ZIP code */
            clubCode: z.string(),
            // /** The plan code determined via ZIP code */
            // planCode: z.string(),
        }),
    })
    .merge(OfferStateSchema);

export const LoyaltyPolicyStatusEnumSchema = z.enum(["approved", "alreadyused"]);

export const LoyaltyApplicationStateSchema = z.object({
    ...BaseSchema.shape,
    applicationMode: z.literal(ApplicationModeEnumSchema.Enum.loyalty),
    offer: LoyaltyTierSchema.array(),
    membershipLevel: z.number(),
    clubSpecificData: z.object({
        ...BaseSchema.shape.clubSpecificData.shape,
        /** The plan code determined via ZIP code */
        planCode: LoyaltyTravelAccidentPlanCodesSchema,
    }),
    application: StrictApplicationSchema.and(
        z.object({
            coverageOptions: BenefitOptionSchema.array(),
            selectedCoverageTier: z.string(),
            selectedCoverageType: CoverageTypesSchema,

            policyStatus: PolicyStatusSchema.optional(), // Not filled in until after submission
        })
    ),
});
export type LoyaltyApplicationState = z.infer<typeof LoyaltyApplicationStateSchema>;

const WillReplacePolicySchema = z.object({
    willReplacePolicy: z.literal(true),
    policyToReplace: PolicyToReplaceSchema,
});

const WillNotReplacePolicySchema = z.object({
    willReplacePolicy: z.literal(false),
});

const PolicyReplacement = z.discriminatedUnion("willReplacePolicy", [WillReplacePolicySchema, WillNotReplacePolicySchema]);

export const DirectTermPolicyStatusEnumSchema = z.enum(["approved", "declined", "refertounderwriter", "alreadyused"]);

export const DirectTermApplicationStateSchema = z.object({
    ...BaseSchema.shape,
    applicationMode: z.literal(ApplicationModeEnumSchema.Enum.directterm),
    offer: DirectTermOfferSchema.array(),
    clubSpecificData: z.object({
        ...BaseSchema.shape.clubSpecificData.shape,
        /** The plan code determined via ZIP code */
        planCode: DirectTermPlanCodesSchema,
    }),
    application: StrictApplicationSchema.and(
        z.object({
            hasUsedNicotineLastYear: z.boolean(),
            gender: GendersSchema,

            coverageOptions: QuoteOptionSchema.array(),
            coverageAmounts: z.array(z.number()),

            height: HeightSchema,
            weight: z.number().int(),
            hannoverId: z.string(),

            policyStatus: DirectTermPolicyStatusEnumSchema.optional(), // Not filled in until after submission
        })
    )
        .and(HealthQuestionsSchema)
        .and(PolicyReplacement),
});
export type DirectTermApplicationState = z.infer<typeof DirectTermApplicationStateSchema>;

export const RootStateSchema = z.discriminatedUnion("applicationMode", [
    LoyaltyApplicationStateSchema,
    DirectTermApplicationStateSchema,
]);
export type RootState = z.infer<typeof RootStateSchema>;
