import { ApplicationModeEnum, ApplicationState, ApplicationStateSchema, ApplicationStates } from "./ApplicationStateSchema";
import {
    ActionTypeEnum,
    AgreeToConsentAnsweredAction,
    ApplicationProcessedAction,
    BeneficiarySelectedAction,
    ClubIdentifiedAction,
    CoverageSelectedAction,
    HealthQuestionsAnsweredAction,
    LandingPageVisitedAction,
    OfferFoundAction,
    PaymentTokenReceivedAction,
    PersonalInfoVerifiedAction,
    QuotesFoundAction,
    ReducerActionSchema,
    SecondaryAddresseeSubmittedAction,
} from "types/ActionTypes";
import { genericInvitationCodeRegex, invitationCodeRegex, purlCodeRegex, uidParameterRegex } from "constants/validations";
import { z } from "zod";

const UNKNOWN_AAA_MEMBER_NUMBER = "0C000000000000000";

function toSpouseApplicationId(memberApplicationId: string | null | undefined) {
    return `${memberApplicationId!.substring(0, 1)}1${memberApplicationId!.substring(2, 10)}`;
}

function reducer<TState extends ApplicationStates>(state: TState, action: any): TState {
    const parseResults = ReducerActionSchema.safeParse(action);

    if (!parseResults.success) {
        console.error(`Error parsing action of type ${action.type}`);
        console.error(parseResults.error);
        return state;
    }

    switch (action.type) {
        case ActionTypeEnum.enum.LANDING_PAGE_VISITED:
            return handleLandingPageVisited(state, action);
        case ActionTypeEnum.enum.OFFER_FOUND:
            return handleOfferFound(state, action);
        case ActionTypeEnum.enum.DIRECTTERM_QUOTES_FOUND:
            return handleQuoteFound(state, action);
        case ActionTypeEnum.enum.LOYALTY_QUOTES_FOUND:
            return handleQuoteFound(state, action);
        case ActionTypeEnum.enum.CLUB_IDENTIFIED:
            return handleClubIdentified(state, action);
        case ActionTypeEnum.enum.COVERAGE_SELECTED:
            return handleCoverageSelected(state, action);
        case ActionTypeEnum.enum.PERSONAL_INFO_VERIFIED:
            return handlePersonalInformationVerified(state, action);
        case ActionTypeEnum.enum.BENEFICIARY_SELECTED:
            return handleBeneficiarySelected(state, action);
        case ActionTypeEnum.enum.SECONDARY_ADDRESSEE_SUBMITTED:
            return handleSecondaryAddresseeSubmitted(state, action);
        case ActionTypeEnum.enum.HEALTH_QUESTIONS_ANSWERED:
            return handleHealthQuestionsAnswered(state, action);
        case ActionTypeEnum.enum.PAYMENT_TOKEN_RECEIVED:
            return handlePaymentTokenReceived(state, action);
        case ActionTypeEnum.enum.AGREE_TO_CONSENT_ANSWERED:
            return handleAgreeToConsentAnswered(state, action);
        case ActionTypeEnum.enum.APPLICATION_PROCESSED:
            return handleApplicationProcessed(state, action);
        case ActionTypeEnum.enum.APPLICATION_COMPLETED:
            return handleApplicationCompleted(state, action);
        default:
            return state;
    }
}

function handleApplicationCompleted<TState extends ApplicationStates>(
    state: TState,
    action: ApplicationProcessedAction
): TState {
    return { ...state };
}

function handleApplicationProcessed<TState extends ApplicationStates>(
    state: TState,
    action: ApplicationProcessedAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            policyStatus: action.policyStatus,
        },
    };
}

function handleAgreeToConsentAnswered<TState extends ApplicationStates>(
    state: TState,
    action: AgreeToConsentAnsweredAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            agreeToConsent: action.agreeToConsent,
        },
    };
}

function handlePaymentTokenReceived<TState extends ApplicationStates>(
    state: TState,
    action: PaymentTokenReceivedAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            paymentToken: action.paymentToken,
        },
    };
}

function handleHealthQuestionsAnswered<TState extends ApplicationStates>(
    state: TState,
    action: HealthQuestionsAnsweredAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            hasSevereMedicalConditions: action.hasSevereMedicalConditions,
            hadDiagnosticTesting: action.hadDiagnosticTesting,
        },
    };
}

function handleSecondaryAddresseeSubmitted<TState extends ApplicationStates>(
    state: TState,
    action: SecondaryAddresseeSubmittedAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            designatedSecondaryAddressee: action.designatedSecondaryAddressee,
            secondaryAddressee: {
                firstName: action.firstName,
                lastName: action.lastName,
                phone: action.phone,
                addressLine1: action.addressLine1,
                addressLine2: action.addressLine2,
                city: action.city,
                state: action.state,
                zipCode: action.zipCode,
            },
        },
    };
}

function handleBeneficiarySelected<TState extends ApplicationStates>(state: TState, action: BeneficiarySelectedAction): TState {
    return {
        ...state,
        application: {
            ...state.application,
            beneficiary: {
                firstName: action.firstName,
                middleInitial: action.middleInitial,
                lastName: action.lastName,
                relationship: action.relationship,
                percentage: action.percentage,
            },
        },
    };
}

function handlePersonalInformationVerified<TState extends ApplicationStates>(
    state: TState,
    action: PersonalInfoVerifiedAction
): TState {
    const conditionalName =
        state.application?.applicantType === "member" && state.firstName && state.lastName
            ? {}
            : { firstName: action.firstName, middleInitial: action.middleInitial, lastName: action.lastName };

    // We only set the aaaMemberNumber if it was not predefined at the root level (from findOffer or mn parameter)
    const conditionalAaaMemberNumber = state.aaaMemberNumber ? {} : { aaaMemberNumber: action.aaaMemberNumber };

    return {
        ...state,
        application: {
            ...state.application,
            ...conditionalName,
            ...conditionalAaaMemberNumber,

            policyNumber: action.policyNumber,
            hannoverId: action.hannoverId,
            addressLine1: action.addressLine1,
            addressLine2: action.addressLine2,
            city: action.city,
            state: action.state,
            phone: action.phone,
            phoneType: action.phoneType,
            email: action.email,
            height: action.height,
            weight: action.weight,
            willReplacePolicy: action.willReplacePolicy,
            policyToReplace: action.policyToReplace,
        },
    };
}

function handleCoverageSelected<TState extends ApplicationStates>(state: TState, action: CoverageSelectedAction): TState {
    // Ensure selectedCoverageAmount is one of the available coverageOptions
    return {
        ...state,
        application: {
            ...state.application,
            coverageAmounts: action.coverageAmounts,
            selectedCoverageTier: action.selectedCoverageTier,
            selectedCoverageType: action.selectedCoverageType,
            selectedCoverageAmount: action.selectedCoverageAmount,
            selectedCoveragePremium: action.selectedCoveragePremium,
        },
    };
}

function handleClubIdentified<TState extends ApplicationState>(state: TState, action: ClubIdentifiedAction): TState {
    return {
        ...state,
        clubSpecificData: {
            ...state.clubSpecificData,
            clubCode: action.clubCode,
            planCode: action.planCode,
        },
    };
}

function handleQuoteFound<TState extends ApplicationStates>(state: TState, action: QuotesFoundAction): TState {
    const memberDefaultValues =
        action.applicantType !== "member"
            ? {}
            : {
                  firstName: state.firstName!,
                  lastName: state.lastName!,
              };

    const coverageOptions =
        action.type === ActionTypeEnum.Enum.DIRECTTERM_QUOTES_FOUND
            ? state
                  .offer!.map((offer) => parseInt(offer, 10)) // Keep results in the order presented in offer array
                  .map((offer) => action.coverageOptions.find((option) => option.coverageAmount === offer))
                  .filter((option) => !!option)
            : action.coverageOptions;

    const directTermSpecificState = "membershipLevel" in action ? { membershipLevel: action.membershipLevel } : {};
    const loyaltyTravelAccidentSpecificApplicationState =
        "membershipLevel" in action ? {} : { gender: action.gender, hasUsedNicotineLastYear: action.hasUsedNicotineLastYear };

    const applicantSpecificApplicationData =
        state.application && action.applicantType === state.application.applicantType
            ? state.application // We preserve whole application for same applicant
            : {}; // Otherwise we preserve the previously set club territory information

    return {
        ...state,
        ...directTermSpecificState,
        application: {
            applicantType: action.applicantType,
            // Preserves previous application state from previous and subsequent steps if applicant types remain
            // the same, or first time on step
            // ...(state.application && action.applicantType === state.application.applicantType ? state.application : {}),
            ...applicantSpecificApplicationData,

            // Defaults (prefills) values such as name for the member who the offer was addressed to
            ...memberDefaultValues,
            coverageOptions: coverageOptions,

            // Clear out previously selected coverage
            selectedCoverageAmount: undefined,
            selectedCoveragePremium: undefined,
            selectedCoverageTier: undefined,
            selectedCoverageType: undefined,

            zipCode: action.zipCode,
            state: action.state,

            ...loyaltyTravelAccidentSpecificApplicationState,
            dateOfBirth: action.dateOfBirth,
            email: action.email,

            aaaMemberNumber: state.application?.aaaMemberNumber,
            applicationID:
                action.applicantType === "member"
                    ? state.application?.applicationID!
                    : toSpouseApplicationId(state.application?.applicationID),
            keyCode: (action.type === "LOYALTY_QUOTES_FOUND" && action.keyCode) || state.keyCode,
            paymentFrequency: "Monthly",
        },
    };
}

function handleOfferFound<TState extends ApplicationStates>(state: TState, action: OfferFoundAction): TState {
    if (state?.application?.applicationID?.endsWith(action.invitationCode.substring(2))) {
        // Don't modify the state, we have already accounted for the user entering this invitation code
        return state;
    }

    // Only override any previously established member number if find offer returned a real member number
    const aaaMemberNumber =
        action.aaaMemberNumber && action.aaaMemberNumber !== UNKNOWN_AAA_MEMBER_NUMBER
            ? action.aaaMemberNumber
            : state.aaaMemberNumber;

    return {
        ...state,

        // The invitationCode at the root represents initial invitation code
        invitationCode: state.invitationCode || action.invitationCode,
        firstName: action.firstName,
        // LATER: Set middleInitial here once it is made available from /findOffer endpoint
        lastName: action.lastName,
        planCode: action.planCode,
        offer: action.offer,
        memberOfferAvailable: action.memberOfferAvailable,
        spouseOfferAvailable: action.spouseOfferAvailable,
        keyCode: action.keyCode,
        aaaMemberNumber: aaaMemberNumber,
        membershipLength: state.membershipLength || action.membershipLength,
        memberSince: state.memberSince || action.memberSince,
        gender: action.gender,

        application: {
            applicationID: action.invitationCode,
            aaaMemberNumber: aaaMemberNumber,
        },
    };
}

const CommonParametersSchema = z.object({
    cc: z
        .string()
        .regex(/^[\da-z]{3}$/i)
        .optional(),
    sc: z.string().regex(/^[\da-z]{1,3}$/i),
    cmp: z.string().optional(),
    uid: z.string().regex(uidParameterRegex),
    purl: z.string().regex(purlCodeRegex).optional(),
    skip: z
        .string()
        .regex(/welcome/)
        .optional(),
});
const CommonParametersPartialSchema = CommonParametersSchema.partial();
type CommonParameters = z.infer<typeof CommonParametersPartialSchema>;

const LoyaltyClubOfferParametersSchema = z
    .object({
        // New parameters for supporting MLTA
        jd: z.string().date(),
        my: z
            .string()
            .regex(/^\d{1,2}$/i)
            .optional(),
        mn: z
            .string()
            .regex(/^\d{16}$/i)
            .optional(),
    })
    .merge(CommonParametersSchema);
type LoyaltyClubOfferParameters = z.infer<typeof LoyaltyClubOfferParametersSchema>;

type ParameterValidationResult =
    | {
          applicationMode: "loyalty";
          success: true;
          data: LoyaltyClubOfferParameters;
      }
    | {
          success: true;
          data: CommonParameters;
      }
    | {
          success: false;
          errors: string[];
      };

function validateParameters(
    applicationMode: ApplicationModeEnum,
    url: string,
    params: Map<string, string>
): ParameterValidationResult {
    if (applicationMode === "loyalty") {
        const uid = params.get("uid");
        const isClubOffer = uid && genericInvitationCodeRegex.test(uid);
        if (isClubOffer) {
            const r1 = LoyaltyClubOfferParametersSchema.safeParse(Object.fromEntries(params));
            return r1.success
                ? { applicationMode: "loyalty", success: true, data: r1.data }
                : {
                      success: false,
                      errors: r1.error?.issues?.map((i) => i.path.toString()) || [],
                  };
        }
    }

    const r2 = CommonParametersPartialSchema.safeParse(Object.fromEntries(params));
    return r2.success
        ? { success: true, data: r2.data }
        : { success: false, errors: r2.error?.issues?.map((i) => i.path.toString()) || [] };
}

export function handleLandingPageVisited<TState extends ApplicationStates>(
    state: TState,
    action: LandingPageVisitedAction
): TState {
    const { url, params } = action;

    const match = new URL(url).hostname.match(/(?<SUBDOMAIN>[^.]+)\.((localhost)|([^.]+.[^.]+))/);
    const subdomain = params.get("subdomain") || match?.groups?.SUBDOMAIN || "directterm";
    const applicationMode = subdomain as ApplicationModeEnum;

    const results = validateParameters(applicationMode || state.applicationMode, url, params);
    if (!results.success) {
        return {
            ...state,
            parameterErrors: results.errors,
        };
    }
    const typedParameters = results.data;
    const uid = typedParameters.uid;
    const purl = typedParameters.purl;

    const timeout = params.get("timeout");
    const promptBeforeIdle = params.get("promptBeforeIdle");

    const invitationCode = typeof uid === "undefined" ? state.invitationCode : invitationCodeRegex.exec(uid)?.[0];

    // FUTURE: Ensure DirectTerm landings require uid or purl when skip=welcome
    const productSpecificState =
        "applicationMode" in results && results.applicationMode === "loyalty"
            ? {
                  memberSince: results.data.jd,
                  aaaMemberNumber: results.data.mn,
                  membershipLength: results.data.my && parseInt(results.data.my, 10),
              }
            : {};

    return {
        ...state,
        applicationMode: applicationMode,
        invitationCode: invitationCode,

        session: {
            ...state.session,

            ...(timeout && promptBeforeIdle
                ? { timeout: parseInt(timeout), promptBeforeIdle: parseInt(promptBeforeIdle) }
                : {}),
        },

        campaign: {
            ...state.campaign,
            clubCode: typedParameters.cc ?? state?.campaign?.clubCode,
            leadSource: typedParameters.sc ?? state?.campaign?.leadSource,
            campaignCode: typedParameters.cmp ?? state?.campaign?.campaignCode,
            campaignUrl: url ?? state.campaign?.campaignUrl,
            offerPurl: purl,
        },
        skipWelcome: typedParameters.skip === "welcome" || isAutoSkipScenario({ uid, purl }),
        ...productSpecificState,
    };
}

function isAutoSkipScenario({ uid, purl }: { uid?: string; purl?: string }): boolean {
    return (typeof uid !== "undefined" && genericInvitationCodeRegex.test(uid)) || typeof purl !== "undefined";
}

function reducerWrapper<TState extends ApplicationStates>(state: TState, action: any): TState {
    logStateErrors(state);
    const newState = reducer(state, action);
    logStateErrors(newState);
    return newState;
}

function logStateErrors(state: ApplicationState) {
    try {
        const parseResults = ApplicationStateSchema.deepPartial().safeParse(state);
        if (!parseResults.success) {
            parseResults.error.issues.forEach((e) => console.warn(`${e.path.join(".")}: ${e.message}`));
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export { reducerWrapper as reducer };
